/*=============================================
=            CSS Index            =
=============================================

    -> Helper CSS
    -> Default CSS
    -> Spacing
    -> Header
    -> Footer
    -> Sidebar
    -> Button
    -> Section title
    -> Backgrounds
    -> Scroll top
    -> Breadcrumb
    -> Pagination
    -> Preloader
    -> Hero slider
    -> Service grid slider
    -> Video cta
    -> Project slider
    -> Team
    -> Testimonial slider
    -> Service tab
    -> Fun fact
    -> Feature icon
    -> Project gallery
    -> Fun fact
    -> Blog
    -> About
    -> Service
    -> Project
    -> Contact
    -> 404
    -> Landing page
    
 
/*=====  End of CSS Index  ======*/


    @import '0-base/variables';
    @import '0-base/mixins';
    @import '0-base/rfs';
    @import '0-base/helper';
    @import '1-global/default';
    @import '1-global/spacing';
    @import '2-layout/header';
    @import '2-layout/footer';
    @import '2-layout/sidebar'; 
    @import '3-elements/button';
    @import '3-elements/section-title';
    @import '3-elements/backgrounds';
    @import '3-elements/scroll-top';
    @import '3-elements/breadcrumb';
    @import '3-elements/pagination';
    @import '3-elements/preloader';
    @import '4-sections/hero-slider';
    @import '4-sections/service-grid-slider';
    @import '4-sections/video-cta';
    @import '4-sections/project-slider';
    @import '4-sections/team';
    @import '4-sections/testimonial-slider';
    @import '4-sections/service-tab';
    @import '4-sections/fun-fact';
    @import '4-sections/feature-icon';
    @import '4-sections/project-gallery';
    @import '5-pages/blog';
    @import '5-pages/about';
    @import '5-pages/service';
    @import '5-pages/project';
    @import '5-pages/contact';
    @import '5-pages/404';
    @import '5-pages/landing'; 


    .cc-title{
        text-transform: uppercase;
        display: block;
        margin-bottom: 48px;
        text-align: center;
    }

    .bg-image {
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        &.series {
            padding-top: 100%;
            .team-list & {
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &.map {
            padding-top: 115%;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            @media screen and (min-width: 992px) {
                margin-right: -30px;
            }
        }

        &.team {
            padding-top: 160%;
            margin-left: -15px;
            @media #{$tablet-device,$large-mobile} {
                padding-top: 100%;
                margin-right: -15px;
            }
        }

        .slider-gallery & {
            padding-top: 49%;
        }
        .service-page & {
            padding-top: 75%;
            transition: all ease .5s;
        }
    }
    .service-desc {
        font-size: 16px;
        font-family: $poppins;
        margin-bottom: 60px;
        text-align: justify;
    }

    .team-wrap {
        margin-top: 20px;
        @media screen and (min-width: 991px) {            
            padding-right: 20px;
        }
        @media #{$tablet-device, $large-mobile} {
            padding-bottom: 20px;
         }
        .team-info {
            font-family: $poppins;
            h4 {
                font-size: 32px;
            }
            h5 {
                font-size: 16px;
            }
            p {
                white-space: pre-wrap;
                text-align: justify;
                font-size: 15.5px
            }
        }
    }

    .icon {
        .icon-awesome {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
        }
    }

    .line {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 1px;
            background-color: $black;
            width: 100%;
            @media screen {
                
            }
            
        }
    }

    .padding-inside {
        .brand-wrapper {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }

    .team-list {
        margin-top: 30px;
        .team-person {
            @media screen and (min-width: 768px) {
                padding: 0;
            }
            @media #{$large-mobile} {
                margin-bottom: 20px;
            }
            button {
                font-size: 16px;
                padding-left: 0;
            }
        }
        .title {
            font-size: 18px;
        }
        .sub-title {
            font-size: 16px;
            color: $white;
        }
    }

    .modal-close {
        position: absolute;
        display: block;
        top: 25px;
        left: auto;
        right: 25px;
        color: $theme-color--default;
        z-index: 5;
        cursor: pointer;
        i {
            font-size: 48px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        @media #{$tablet-device, $large-mobile} {
           background-color: $white;
           width: 56px;
           height: 56px;
           top: 15px;
           border-radius: 50%;
        }
    }

    .about-image-3 {
        .order-1 & {
            margin-left: 10px;
        }

        .order-3 & {
            margin-right: 10px;
        }

    }

    .caption {
        margin-top: 8px;
        font-size: 16px;
        font-family: $poppins;
        font-weight: bold;
        text-align: center;
        @media screen and (max-width: 768px) {
            font-size: 12px;
        }
    }